import React, {Component} from 'react';
import PropTypes from 'prop-types';
const propTypes = {
    supportedMedia: ['twitter','instagram']
}

const defaultProps = {
    supportedMedia: ['twitter','instagram']
}

export default class SocialMediaBar extends Component {
    render() {
       let list= this.props.supportedMedia.map((media) =>{
            return <li><a target="_blank" href={`https://${media}.com/dopeladyclub`}><h2 className='neonText'>{media}</h2></a></li>
        });

        return <ul>{list}</ul>
    }
}

SocialMediaBar.propTypes = propTypes;
SocialMediaBar.defaultProps = defaultProps;